.order-text {
    margin-left: 50px;
    margin-bottom: 30px;
}

.text_checkbox {
    display: flex;
    align-items: center;
    min-width: max-content;
    font-family: ProtoGrotesk;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

}

.text {
    display: flex;
    width: 350px;
}

.text p {
    font-size: 12px;
}

.checkbox-container {
    display: grid;
    grid-template-columns: 1fr 32fr;

    gap: 10px;
}

.insurance-container-checkbox {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #f2f2f2;
    padding: 24px;
    display: none;
}

.event_name {
    margin-left: 24px;
    margin-top: 16px;
}

.event_location {
    margin-left: 24px;
    margin-top: 8px;
    margin-bottom: 16px;
    color: #A6A6A6;
}

.event_adults {
    margin-left: 24px;
}

.event_children {
    margin-left: 24px;
    margin-bottom: 32px;
}

.onoffswitch {
    position: relative; width: 48px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 24px; padding: 0; line-height: 24px;
    border: 2px solid #E3E3E3; border-radius: 24px;
    background-color: #D9D9D9;
    transition: background-color 0.3s ease-in;
}
.onoffswitch-label:before {
    content: "";
    display: block; width: 24px; margin: 0px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 22px;
    border: 2px solid #E3E3E3; border-radius: 24px;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: #FFCF08;
}
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
    border-color: #FFCF08;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px;
}

.switch_div {
    display: flex;
    justify-items: center;
    align-items: center;
}

.switch_text {
    margin-left: 16px;
}
