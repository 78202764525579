.navbar {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top:  12px;
    padding-bottom: 12px;
    background-color: #fff;
}

.navbar_left {
    display: flex;
    align-items: center;
}

.navbar_right {
    display: flex;
    align-items: center;
}
.header_line {
    background: #d9d9d9;
    width: 2px;
    height: 28px;
    border-radius: 2px;
    margin: 0 16px;
}

.russia_down {
    display: flex;
    align-items: center;
    gap: 16px;
}

.navbar__link {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 12px;
}

.menu-link {
    margin-left: 20px;
}

.navbar__items {
    display: flex;

}

.navbar__item {
    margin-left: 20px;
    display: flex;
    align-items: center;

}

@media only screen and (max-width: 1200px) {
    .navbar {
        max-width: 95%;
    }
    .link_text {
        display: none;
    }
    .header_line {
        margin: 0 8px;
    }
    .russia_down {
        gap: 8px;
    }
    .menu-link {
        margin-left: 0;
    }
}

@media only screen and (max-width: 768px) {
    .navbar {
        max-width: 100%;
        justify-content: center;
    }
    .navbar_left {
        justify-content: flex-start;
    }
    .navbar__link {
        gap: 2px;
    }
}

@media only screen and (max-width: 460px) {
    .bonus-link {
        display: none;
    }
}

@media only screen and (max-width: 410px) {
    .flags-icon {
        display: none;
    }
    .navbar_right {
        justify-content: space-between;
    }
}