.faq_container {
    width: 90%;
    margin: 50px auto;
    margin-bottom: 170px;
}

.faq_content {
    margin-top: 30px;
}

.faq_item {
    background: #fff;
    margin-bottom: 8px;
    padding: 10px 20px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.faq-_tem.open .faq_answer {
    padding: 10px 0;
}

.faq_question {
    font-size: 18px;
    line-height: 1.4;
    position: relative;
}

.faq_answer {
    color: #666;
    border-top: 1px solid #eee;
    margin-top: 10px;
    display: none;
}

.faq_toggle {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 24px;
    color: #666;
}

.faq_container h2 {
}