.banner_container {
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: 300px;
    border-radius: 26px;
    background: linear-gradient(90deg, #76B9DC 0%, #70D1DE 100%);
    background-size: cover;
    margin: auto;
    margin-bottom: 32px;
}

.banner_img {
    opacity: 0.5;
}

.banner_content {
    height: 600px;
    display: flex;
    flex-direction: column;
    background-image: url("./img/banner.png");
    background-size: 100% auto;
    background-position: 0% 125%;
    background-repeat: no-repeat;
}

.banner_text {
    width: 85%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
    gap: 20px;
}

.banner_title {
    width: 85%;
    font-size: 24px;
    color: #ffffff;
    line-height: 28px;
}

.banner_description {
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-shadow: 0px 0px 8px #76B9DC;
}

.button {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.banner_button {
    width: 85%;
    background-color: #FFD700;
    border: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18px;
    padding: 10px 20px;
    font-size: 1em;
    color: #333;
    border-radius: 12px;
}

