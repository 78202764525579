.step-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: gray;
}

.step {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.step-number {
    margin-right: 10px;
    font-weight: bold;
}

.step-label {
    font-size: 16px;
}

.completed {
    color: black; /* or any indication for completed steps */
}