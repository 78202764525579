.container {
    overflow: hidden;
    margin: 0 auto;
}

.insurance {
    gap: 16px;
    margin-top: 10px;
}


.insurance-wave {
    position: absolute;
    top: 20px;
    z-index: -1;
    width: 100%;
}

.section h2 {
    font-size: 20px;
    margin: 0;
    margin-bottom: 14px;
}

.section p {
    margin-bottom: 1px;
}

@media only screen and (max-width: 1200px)  {
    .insurance-container{
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .section {
        width: 400px;
    }
}

@media only screen and (max-width: 990px) {
    .insurance {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }


    .insurance-container h2 {
        margin: 0 auto;
    }

    .section {
        width: 450px;
    }
}