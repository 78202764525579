.footer {
    background-color: #000000;
    padding-top: 20px;
    padding-left: 10px;
    bottom:0;
    width: 100%;
}

.footerContainerUPLeft h2 {
    color: white;
}

.footerContainerUPLeft p {
    color: white;
}

.footerContainerUPLeft p:nth-child(1) {
    margin-bottom: 10px;
}

.footerContainerUPLeft h2 {
    font-size: 16px;
}

.footerContainerUPLeft p {
    font-size: 16px;
}

.footerContainerUPLeft h2:nth-child(1) {
    color: #9c9c9c;
    font-size: 20px;
}

