.events {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 60px;
}

.event_item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 300px;
    height: 400px;
    margin-left: 8px;
    margin-right: 8px;
}

.backgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
    border-radius: 24px;
}

.event_text {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
}

.event_text h3 {
    font-size: 24px;
}

.event_text p {
    font-size: 16px;
}

.event_text h3,
.event_text p {
    color: white; /* Замените на желаемый цвет */
    text-decoration: none;
}

.button_container {
    display: flex;
    justify-content: center;
}

.header1 {
    margin-top: 48px;
    margin-left: 16px;
}

.events_scroll {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
    white-space: nowrap;
}
