.slider {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    margin: 0 auto;
    margin-top: 40px;
}

.slider_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
}

.slider_text {
    color: #000000;
    width: 65%;
    margin-left: 40px;
}

.slider h2 {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 700;
    margin-left: 0;
    margin-bottom: 10px;
}

.slider_text p {
    font-size: 14px;
    margin-top: 3px;
    font-weight: 400;
    line-height: 1.5;
}

.slider_buttons {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.slider_buttons span {
    font-family: ProtoGrotesk, sans-serif;
    font-size: 20px;
    color: #A6A6A6;
    margin-right: 5px;
    margin-left: 5px;
}

.slider_button {
    width: 35px;
    height: 35px;
    border: white;
    background-color: #ffcf08;
    border-radius: 5px;
}

.slider img {
    width: 200px; /* Задаём ширину, например, чтобы изображение занимало всю ширину контейнера */
    height: 200px; /* Задаём фиксированную высоту */
    object-fit: cover; /* Изображение будет покрывать всю область, сохраняя пропорции */
}

@media (max-width: 768px) {
    .slider_buttons {
        display: none;
    }
}