body, .main-container {
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 0 !important;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}