@font-face {
    font-family: "ProtoGrotesk";
    src: local("ProtoGrotesk"), url("../fonts/protoGrotesk/ProtoGrotesk-Regular.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "SuisseIntl";
    src: local("SuisseIntl"), url("../fonts/suisseIntl/SuisseIntl-Regular.otf") format("truetype");
    font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
    font-family: ProtoGrotesk, sans-serif;
    font-weight: bold;
}

p {
    font-family: "SuisseIntl", sans-serif;
    font-weight: bold;
}

span {
    font-family: "ProtoGrotesk", sans-serif;
    font-weight: bold;
}

label {
    font-family: "ProtoGrotesk", sans-serif;
    font-weight: bold;
}