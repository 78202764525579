.tickets {
    overflow: hidden;
}

.navbar {
    width: 100vw;
    height: auto;
}

.places svg {
    width: 90vw;
    height: auto;
    margin-left: 25px;
}

.path  svg {
    width: 80vw;
    height: auto;
    margin-top: 10px;
    margin-left: 25px;
}

.info svg {
    width: 100vw;
    height: 50vw;
    margin-top: 10px;
    margin: 0 auto;
    margin-left: 25px;
}

.russpass svg {
    width: 90vw;
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 25px;
}

.user svg {
    width: 90vw;
    margin: 0 auto;
    margin-top: 10px;
    margin-left: 15px;
    margin-left: 25px;
}

.pushkin svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 25px;
}

.promo svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 25px;
}
.whyins svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
}
.toBuy svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
}

.footer svg {
    width: 100vw;
    height: auto;
    margin-top: 10px;
}


