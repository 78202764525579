.steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}

.steps_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
}

.step_img {
    object-fit: cover;
}

.step_item {
    display: flex;
    flex-direction: row;
}

.step_item:nth-child(1) {
    align-items: center;
    width: 90%;
}

.step_text {
    width: 350px;
}

.step_item:nth-child(2) .step_text {
    width: 220px;
}

.step_item:nth-child(2),
.step_item:nth-child(3) {
    margin-top: 20px;
    width: 630px;
    border: 1px solid #d9d9d9;
    padding: 15px;
    border-radius: 10px;
}

.step_item h2 {
    margin: 0;
    margin-bottom: 14px;
}

.step_item p {
    margin-bottom: 1px;
}

.step_text {
    width: 350px;
}

.step_text h2:nth-child(1) {
    color: #9c9c9c;
}

@media only screen and (max-width: 1200px) {
    .step_item:nth-child(2),
    .step_item:nth-child(3) {
        width: 90%;
    }
    .step_img {
        width: 400px;
        object-fit: cover;
    }
}

@media only screen and (max-width: 860px) {
    .step_item:nth-child(1) {
        display: flex;
        flex-direction: column;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        padding-bottom: 15px;
        padding-top: 15px;
    }
}


