.profile_events {
    width: 460px;
    height: 1200px;
    margin: 0 auto;
}

.navbar {
    width: 100vw;
    height: auto;
}

.profile  svg {
    width: 100vw;
    height: auto;
    margin-top: 10px;
}

.path svg {
    width: 100vw;
    height: auto;
    margin-top: 10px;
}



.trips_qr h2 {
    margin-left: 20px;
    margin-bottom: 10px;

}

.pasport svg {
    width: 100vw;
    height: auto;
    margin-left: 10px;
}

.trips_qr {
    margin: 0 auto;
}

.trips_qr svg {
    width: 100vw;
    height: auto;
    padding: 20px;
}


.contacts svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-left: 30px;
}

.contacts svg:nth-child(3) {
    margin: 0 auto;
}

.profile_event {
    width: 80%;
    margin: 0 auto;

}

.profile_event_item  svg{
    width: 100vw;
    height: auto;
}

.last_order {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
}

.last_order h2 {
    font-size: 16px;
}

.last_order h3 {
    font-size: 12px;
}

.buttons {
    width: 90%;
    margin: 10px auto;
}


