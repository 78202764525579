.insurance_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    width: 80%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom:  60px;
}

.insurance_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.insurance_text {
    margin-bottom: 40px;
}

.insurance_sections {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
}


.section {
    width: 630px;
    border: 1px solid #d9d9d9;
    padding: 15px;
    border-radius: 10px;
    background-color: white;
}

.section h2 {
    margin-bottom: 16px;
}

@media only screen and (max-width: 1400px) {
    .insurance-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .insurance_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .insurance_text {
        text-align: center;
    }
    .section {
        width: 400px;
    }

    .section h2 {
        margin-bottom: 12px;
    }
}