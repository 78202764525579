.place {
    overflow: hidden;
}

.navbar {
    width: 100vw;
    height: auto;
}

.places svg {
    width: 100vw;
    height: auto;
}

.path  svg {
    width: 80vw;
    height: auto;
    margin-top: 10px;

}

.title svg {
    width: 100vw;
    height: 50vw;
    margin-top: 10px;
    margin: 0 auto;

}

.choose svg {
    width: 90vw;
    margin-left: 15px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.about svg {
    width: 90vw;
    margin: 0 auto;
    margin-top: 20px;
    margin-left: 15px;
    /*margin-bottom: 10px;*/
}

.byTicket svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
}

.insurance svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
}
.audio svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
}
.kreml svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
}

.advert svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
}

.feedback svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 100px;
}

.map svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 100px;
}

.interesting svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 100px;
}

.footer svg {
    width: 100vw;
    height: auto;
    margin-top: 10px;
}


