.event_details {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.event_details_text {
    width: 100%;
}

.event_details_text p {
    margin-left: 24px;
}

.event_details_text h1 {
    margin-left: 24px;
    margin-top: 16px;
}

.event_details_text h3 {
    margin-left: 24px;
    margin-top: 8px;
    margin-bottom: 16px;
    color: #A6A6A6;
}

.event_details_text2 {
    margin-bottom: 30px;
}

.img {
    object-fit: cover;
    margin: 0 auto;
}

.preview {
    object-fit: cover;
    width: 100%;
}

.event_additional {
    width: 100vw;
    height: auto;
    margin-top: 16px;
}

.event_additional svg {
    width: 100vw;
    height: auto;
}

.bread {
    margin-top: 16px;
    margin-bottom: 16px;
    color: #0370C7;
}
