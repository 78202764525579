.user {
    width: 80%;
    margin: 0 auto;
}

.user h1 {
    font-size: 24px;
    font-weight: 700;
}

.user h3 {
    font-size: 16px;
    color: #9c9c9c;
}