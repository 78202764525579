.why_container {
    width: 80%;
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
}

.why_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.why_text{
    margin-bottom: 25px;
}

.why_section {
    width: 45%;
}

.cases {
    width: 45%;
}

.why_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

.why_item_text h2 {
    font-size: 18px;
}

.why_item_text p {
    font-size: 14px;
    margin-top: 3px;
}

.why_text h2 {
    margin-bottom: 0;
    margin-left: 0;
}

.why_text p {
    font-size: 15px;
    margin-top: 3px;
}

@media only screen and (max-width: 990px) {
    .why_container {
        display: flex;
        justify-content: center;
    }
    .why_section {
        width: 85%;
        margin: 0 auto;
    }
}
