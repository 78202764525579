.ticketInProfile {
    overflow: hidden;
}

.navbar {
    width: 100vw;
    height: auto;
}

.path  svg {
    width: 80vw;
    height: auto;
    margin-top: 10px;
    margin-left: 25px;
}

.places svg {
    width: 90vw;
    height: auto;
    margin-left: 25px;
}

.profile svg {
    width: 60vw;
    height: auto;
    margin-top: 10px;
    margin: 0 auto;
    margin-left: 20px;
}



.back svg {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.button {
    width: 45px;
    height: auto;
    margin: 0 auto;

}

.button svg {
    width: 90vw;
    margin: 0 auto;
    margin-top: 10px;
    margin-left: 15px;
    margin-left: 25px;
}

.passport svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 25px;
}

.ticket svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 25px;
}
.contacts svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
}
.info svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
}

.feedback svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
}

.buttonExc svg {
    width: 100vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 50px;
}

.footer svg {
    width: 100vw;
    height: auto;
    margin-top: 10px;
}


