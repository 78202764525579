.main {
    height: 1200px;
}

.navbar {
    width: 100vw;
    height: auto;
}

.plans {
    width: 100%;
    background: url("../../components/searchField/back.svg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 20px;
}
.plans  svg {
    width: 100px;
    margin-top: 10px;

}

.plans_text svg {
    display: flex;
    justify-content: center;
    width: 80vw;
    height: 30vw;
    margin: 0 auto;
    margin-bottom: 10px;
}

.plans_search svg {
    display: flex;
    justify-content: center;
    width: 90vw;
    height: 50vw;
    margin: 10px auto 20px auto;

}

.places svg {
    width: 100vw;
    height: 90vw;
    margin-left: 15px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.place_text svg {
    width: 80vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-left: 15px;
    /*margin-bottom: 10px;*/
}

.place_button {
    display: flex;
    justify-content: center;
}

.place_button svg {
    width: 80vw;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 100px;
}

.banner {
    width: 100%;
    background: url("./img/wave.svg");
    background-repeat: no-repeat;
    background-size: cover;
}


