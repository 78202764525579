.home {
    height: 70vh;
}

.home > * {
    margin-left: 24px;
    margin-bottom: 24px;
}

.t1 {
    margin-top: 24px;
}

.home > button {
    margin-top: 24px;
    width: 85%;
    border: none;
    margin-bottom: 18px;
    padding: 10px 20px;
    font-size: 1em;
    color: #333;
    background-color: #FFCF08;
    border-radius: 12px;
}
