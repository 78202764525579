
.wave {
    position: absolute;
    top: 300px;
    z-index: -1;
}

.button_container {
    display: flex;
    justify-content: center;
}