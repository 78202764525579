.button {
    width: 85%;
    border: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18px;
    padding: 10px 20px;
    font-size: 1em;
    color: #333;
    border-radius: 12px;
}