.div_success {
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div_success img {
    margin-top: 128px;
}

.div_success h2 {
    margin-top: 32px;
}

.div_success button {
    margin-top: 32px;
}
