form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    border-radius: 16px;
    background-color: #F5F5F5;
    margin: 0 24px;
}

form label {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
}

form input {
    width: 100%;
    padding: 10px;
    border-radius: 16px;
    border: 1px solid #ccc;
    min-height: 48px;
    font-size: 16px;
    font-weight: bolder;
    font-family: ProtoGrotesk;
}

form button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #007BFF;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

form button:hover {
    background-color: #0056b3;
}

form button {
    width: 100% !important;
    height: 48px !important;
}
